<template>
  <div class="rightCpn">
    <div class="current">当前选中: {{ currentItem.name || "---" }}</div>
    <div class="search">
      <div class="left">综合查询</div>
      <el-input
        v-model="keyword"
        clearable
        placeholder="请输入工号/姓名/手机号"
      ></el-input>
      <el-button type="primary" icon="el-icon-search" @click="serch"
        >查询</el-button
      >
    </div>
    <el-row>
      <el-col :span="6">
        <el-button
          type="primary"
          @click="added"
          icon="el-icon-circle-plus-outline"
          style="margin-right: 10px;"
          >新增</el-button
        >
        <!-- <el-popconfirm
          title="确定删除吗？"
          @onConfirm="handleDelte"
          @confirm="handleDelte"
        >
          <el-button
            slot="reference"
            type="danger"
            icon="el-icon-remove-outline"
            >删除</el-button
          >
        </el-popconfirm> -->
      </el-col>
    </el-row>
    <el-table
      ref="table"
      :data="tableData"
      style="width: 100%;"
      height="calc(100vh - 272px)"
    >
      <el-table-column
        label="序号"
        type="index"
        width="50"
        align="center"
      ></el-table-column>
      <template v-for="item in tableHeader">
        <el-table-column
          :label="item.name"
          :prop="item.prop"
          :min-width="item.minWidth"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.currPage"
      :page-sizes="[50, 100, 300, 500]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import eventBus from "@/utils/event-bus";

export default {
  props: {},
  data() {
    return {
      keyword: "",
      tableData: [],
      tableHeader: [
        {
          name: "账号",
          prop: "loginName",
          minWidth: "120px"
        },
        {
          name: "用户名称",
          prop: "name",
          minWidth: "100px"
        },
        {
          name: "手机号",
          prop: "phone",
          minWidth: "100px"
        }
        // {
        //   name: "所属组织",
        //   prop: "brand",
        //   minWidth: "100px"
        // }
      ],
      queryInfo: {
        currPage: 1,
        pageSize: 50,
        condition: {}
      },
      total: 0,
      userInfo: JSON.parse(localStorage.getItem("userInfo")).userInfo
    };
  },
  computed: {
    ...mapState("roleMemberManagement", ["currentItem"])
  },
  watch: {
    currentItem: {
      handler(newVal) {
        this.getList();
      },
      deep: true
    }
  },
  created() {
    // this.getList();
    eventBus.on("addSuccess", this.addSuccess);
    eventBus.on("itemClick", this.itemClick);
  },
  destroyed() {
    eventBus.off("addSuccess", this.addSuccess);
    eventBus.off("itemClick", this.itemClick);
  },
  methods: {
    ...mapMutations("roleMemberManagement", ["changeShowDialog2"]),

    async getList() {
      if (
        !this.currentItem &&
        Object.keys(this.currentItem).length === 0 &&
        !this.currentItem.roleCode
      ) {
        return;
      }

      let data = this.queryInfo;
      data.condition.roleCode = this.currentItem.roleCode;
      data.condition.agentCode = this.userInfo.agentCode;
      data.condition.keyword = this.keyword;

      const res = await this.$http.post("/roleUserInfo/list", data);
      if (res.data.code == 200) {
        this.tableData = res.data.data.data;
        this.total = res.data.data.count;
      } else {
        this.$message.error(res.data.message);
      }
    },
    serch() {
      this.getList();
    },
    added() {
      this.changeShowDialog2(true);
    },
    addSuccess() {
      this.getList();
    },
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
    itemClick() {
      // this.getList();
    },
  }
};
</script>

<style lang="less" scoped>
.rightCpn {
  padding: 10px 25px;
  background-color: #fff;
  .current {
    margin-bottom: 10px;
    color: #7f7f7f;
    font-size: 14px;
  }
  .search {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .left {
      color: #7f7f7f;
      font-weight: 600;
      width: 80px;
      font-size: 16px;
    }
    .left::-webkit-scrollbar{
      width: 0;
    }
    /deep/ .el-input {
      width: 200px;
      margin-right: 15px;
    }
  }
}
</style>
