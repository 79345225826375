<template>
  <div class="demo">
    <el-dialog
      title="选择人员"
      :visible.sync="showDialog2"
      width="1000px"
      append-to-body
    >
      <div class="search">
        <div class="left">综合查询</div>
        <el-input
          v-model.trim="keyword"
          placeholder="请输入工号/姓名/手机号"
          clearable
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="serch"
          >查询</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%"
        v-loading="showLoading"
        :row-key="getRowKey"
        :max-height="maxTableHeight"
        @row-click="handleRowClick"
        @select-all="onSelectAll"
        @select="handleSelect"
      >
        <el-table-column
          type="selection"
          width="50"
          class-name="selection-column"
        >
        </el-table-column>
        <el-table-column
          label="序号"
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <template v-for="item in tableHeader">
          <el-table-column
            :label="item.name"
            :prop="item.prop"
            :min-width="item.minWidth"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currPage"
        :page-sizes="[50, 100, 300, 500]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <span slot="footer">
        <el-button size="small" @click="closeDialog">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import eventBus from "@/utils/event-bus";

export default {
  props: {},
  data() {
    return {
      tableData: [],
      showLoading: false,
      tableHeader: [
        {
          name: "账号",
          prop: "loginName",
          minWidth: "120px"
        },
        {
          name: "用户名称",
          prop: "name",
          minWidth: "100px"
        },
        {
          name: "手机号",
          prop: "phone",
          minWidth: "100px"
        }
        // {
        //   name: "所属商户",
        //   prop: "brand",
        //   minWidth: "100px"
        // },
        // {
        //   name: "所属组织",
        //   prop: "brand",
        //   minWidth: "100px"
        // }
      ],
      selectList: [],
      selectTableData: [], // 已选人员
      queryInfo: {
        currPage: 1,
        pageSize: 50,
        condition: {}
      },
      total: 0,
      keyword: "",
      maxTableHeight: "388px",
      userInfo: JSON.parse(localStorage.getItem("userInfo")).userInfo
    };
  },
  computed: {
    ...mapState("roleMemberManagement", ["currentItem"]),
    showDialog2: {
      get() {
        return this.$store.state.roleMemberManagement.showDialog2;
      },
      set(val) {
        this.$store.commit("roleMemberManagement/changeShowDialog2", val);
      }
    }
  },
  watch: {
    showDialog2: {
      async handler(newVal) {
        if (newVal) {
          await this.getList();
          await this.getRenderList();
        } else {
          this.keyword = "";
          this.queryInfo.condition = {};
          this.selectList = [];
        }
      }
    },
    selectList: {
      handler(newVal) {
        console.log("selectList", this.selectList);
        this.selectTableData = newVal?.map(item => item.userCode);
        this.setTableSelection();
      },
      deep: true
    }
  },
  created() {
    // this.getList();
  },
  mounted() {},
  methods: {
    ...mapMutations("roleMemberManagement", ["changeShowDialog2"]),
    async getList() {
      this.showLoading = true;
      //
      let data = this.queryInfo;
      data.condition.agentCode = this.userInfo.agentCode;
      data.condition.keyword = this.keyword;
      const res = await this.$http.post("/userInfo/list", data);
      if (res.data.code == 200) {
        this.tableData = res.data.data.data;
        this.total = res.data.data.count;
        this.showLoading = false;
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 回显
    async getRenderList() {
      const res = await this.$http.post("/roleUserInfo/queryList", {
        roleCode: this.currentItem.roleCode
      });
      if (res.data.code == 200) {
        this.selectList = res.data.data;
        this.selectTableData = res.data.data.map(item => item.userCode);
        this.setTableSelection();
      } else {
        this.$message.error(res.data.message);
      }
    },
    // handleSelectionChange(val) {
    //   this.selectList = val;
    // },
    async serch() {
      await this.getList();
      this.setTableSelection();
    },
    closeDialog() {
      this.changeShowDialog2(false);
    },
    async handleSave() {
      let data = {
        roleCode: this.currentItem.roleCode,
        userCodeList: this.selectList.map(item => item.userCode)
      };
      // 发送请求
      const res = await this.$http.post("/roleUserInfo/createRoleUser", data);
      if (res.data.code === 200) {
        this.$message.success("新增成功");
        this.queryInfo.condition = {};
        this.changeShowDialog2(false);
        this.keyword = "";
        eventBus.emit("addSuccess");
      } else {
        this.$message.error(res.data.message);
      }
    },
    setTableSelection() {
      this.$refs.multipleTable?.clearSelection();
      this.tableData.forEach(row => {
        // console.log(this.selectTableData, row.userCode);
        // console.log(this.selectTableData?.includes(row.userCode));
        if (this.selectTableData?.includes(row.userCode)) {
          this.$refs.multipleTable?.toggleRowSelection(row, true);
        }
      });
    },
    async handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      await this.getList();
      this.setTableSelection();
    },
    async handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      await this.getList();
      this.setTableSelection();
    },
    getRowKey(row) {
      return row.userCode;
    },
    isExists(userCode) {
      let existsIndex = this.selectList?.findIndex(
        item => item.userCode === userCode
      );
      // console.log(existsIndex);
      return existsIndex;
    },
    handleRowClick(row, column, event) {
      let index = this.isExists(row.userCode);
      if (index === -1) {
        this.selectList.push(row);
      } else {
        this.selectList.splice(index, 1);
      }
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    onSelectAll(selection) {
      if (selection.length > 0) {
        this.tableData.forEach(item => {
          let index = this.isExists(item.userCode);
          if (index === -1) {
            this.selectList.push(item);
          }
        });
      } else {
        this.tableData.forEach(item => {
          let index = this.isExists(item.userCode);
          if (index != -1) {
            this.selectList.splice(index, 1);
          }
        });
      }
    },
    handleSelect(selection, row) {
      this.$refs.multipleTable?.clearSelection();

      if (selection.length !== this.tableData.length) {
        this.handleRowClick(row);
      } else {
        this.onSelectAll(selection);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.search {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .left {
    width: 80px;
  }
  /deep/ .el-input {
    width: 200px;
    margin-right: 15px;
  }
}

/deep/ .el-dialog__body {
  padding: 8px 20px;
}
</style>
