<template>
  <div class="roleMemberManagement">
    <div class="left">
      <leftCpn />
    </div>
    <div class="right">
      <rightCpn />
    </div>
    <addRoleDialog />
    <addDialog />
  </div>
</template>

<script>
import leftCpn from "./ch-cpns/leftCpn/index.vue";
import rightCpn from "./ch-cpns/rightCpn/index.vue";
import addRoleDialog from "./ch-cpns/addRoleDialog/index.vue";
import addDialog from "./ch-cpns/addDialog/index.vue";
export default {
  components: { leftCpn, rightCpn, addRoleDialog, addDialog },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeUpdate() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.roleMemberManagement {
  display: flex;
  height: calc(100vh - 104px);
  padding: 8px 10px;
  background: #f5f7f9;
  .left {
    flex: 1;
    margin-right: 10px;
    height: calc(100vh - 96px);
    overflow: auto;
  }
  .right {
    flex: 4;
  }
}
</style>
