<template>
  <div class="leftCpn">
    <div class="top">
      <span>角色</span>
      <el-button style="color:#2e65f4" type="text" @click="roleClick"
        >添加</el-button
      >
    </div>
    <div class="bottom">
      <div class="tit">系统角色</div>
      <template v-for="(item, index) in renderData">
        <el-popover
          placement="right-start"
          width="260"
          title="角色描述"
          trigger="hover"
          :content="item.remark"
          popper-class="role-popover"
        >
          <div
            slot="reference"
            class="item"
            @click="itemClick(item, index)"
            :class="[currentIndex === index ? 'activeClass' : '']"
          >
            <div class="item-tit">{{ item.name }}</div>
          </div>
        </el-popover>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import eventBus from "@/utils/event-bus";

export default {
  props: {},
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")).userInfo,
      renderData: {},
      currentIndex: 0
    };
  },
  created() {
    this.getList();
    eventBus.on("addRoleSuccess", this.addRoleSuccess);
  },
  mounted() {},
  destroyed() {
    eventBus.off("addRoleSuccess", this.addRoleSuccess);
  },
  methods: {
    ...mapMutations("roleMemberManagement", [
      "changeShowDialog1",
      "changeCurrentItem"
    ]),
    roleClick() {
      this.changeShowDialog1(true);
    },
    async getList() {
      const res = await this.$http.post("/roleInfo/getRoleList", {
        agentCode: this.userInfo.agentCode
      });

      if (res.data.code == 200) {
        this.renderData = res.data.data;
        this.changeCurrentItem(this.renderData[0]);
      } else {
        this.$message.error(res.data.message);
      }
    },
    addRoleSuccess() {
      this.getList();
    },
    itemClick(item, index) {
      this.currentIndex = index;
      this.changeCurrentItem(item);
      eventBus.emit("itemClick", item);
    }
  }
};
</script>
<style>
.role-popover {
  max-height: 330px;
  overflow-y: scroll;
  background: #000 !important;
  color: #fff;
}
.role-popover .el-popover__title {
  color: #f59a23;
}
</style>
<style lang="less" scoped>
.leftCpn {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0px 0 20px;
    height: 40px;
    border-bottom: 1px solid #f2f3f4;
    > span {
      color: #333333;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .bottom {
    .tit {
      padding: 4px 20px;
      font-size: 14px;
      color: #ccc;
    }
    .item {
      padding: 10px 20px;
      cursor: pointer;
      border-bottom: 1px solid #f2f3f4;
      .item-tit {
        color: #7f7f7f;
        font-size: 14px;
        font-weight: 500;
      }
      &:hover {
        background-color: #d2defd;
      }
    }
  }
}
.activeClass {
  background-color: #d2defd;
}
</style>
