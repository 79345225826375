<template>
  <div class="fontDialog">
    <el-dialog title="新增角色" :visible.sync="showDialog1" width="600px">
      <el-form label-width="120px">
        <el-row :gutter="10">
          <el-col :span="22">
            <el-form-item label="角色名称：" prop="name">
              <el-input v-model="form.name" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="角色说明：" prop="remark">
              <el-input
                v-model="form.remark"
                type="textarea"
                :autosize="{ minRows: 5 }"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="closeDialog">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import eventBus from "@/utils/event-bus";

export default {
  data() {
    return {
      form: {},
      userInfo: JSON.parse(localStorage.getItem("userInfo")).userInfo
    };
  },
  computed: {
    // ...mapState("roleMemberManagement", ["showDialog1"]),
    showDialog1: {
      get() {
        return this.$store.state.roleMemberManagement.showDialog1;
      },
      set(val) {
        this.$store.commit("roleMemberManagement/changeShowDialog1", val);
      }
    }
  },

  methods: {
    ...mapMutations("roleMemberManagement", ["changeShowDialog1"]),
    async handleSave() {
      if (!this.form.name || this.form.name.length === 0) {
        this.$message.warning("请输入角色名称");
        return;
      }

      let data = {
        ...this.form,
        agentCode: this.userInfo.agentCode,
        agentName: this.userInfo.agentName,
        creator: this.userInfo.userCode
      };
      // 发送请求
      const res = await this.$http.post("/roleInfo/createRole", data);
      if (res.data.code === 200) {
        this.$message.success("新增成功");
        this.changeShowDialog1(false);
        this.form = {};
        eventBus.emit("addRoleSuccess");
      } else {
        this.$message.error(res.data.message);
      }
    },
    closeDialog() {
      this.changeShowDialog1(false);
    }
  }
};
</script>

<style lang="less" scoped></style>
